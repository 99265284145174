
import {defineComponent, reactive, toRefs, onBeforeMount, onMounted,ref} from 'vue'
import axios from '@/api/http.js';
import {ElLoading} from 'element-plus';
import elq from '@/utils/arubaElq.js';

export default defineComponent({
  name: '',
  components: {

  },
  setup() {
    const data = reactive({
      title:'Sponsors',
      sponsorsData: []
    })
    onBeforeMount(() => {

    })
    onMounted(() => {
      getData();
    })
    const getData = () => {
      const loading = ElLoading.service({
        lock: true,
      })
      axios
        .request({
          method: "PATCH",
          url: "/virtual/sponsors",
          data: {
            meetingId: 'a7yyxczuty0qpjo2393gmyad'
            // meetingId: localStorage.eventsMeetingId,
          }
        })
        .then((res) => {
          
          if(res.data.result == 200){
            data.sponsorsData = res.data.sponsors;
            console.log(data.sponsorsData,888)
            data.sponsorsData.map((i) => {
              i.sponsors.map((v) => {
                v.logo = '/files/' + v.logo;
              })
            })
            
          }
          loading.close()
        })
        .catch((err) => {
          console.log(err);
          loading.close()
        });
    }

    const goLink = (i) => {
      elq.elqActivityForm('','Click on Sponsor',i.id);
      elq.nextBeen('','Click on Sponsor',i.id);
      window.open(i.link, '_blank');
    }
    const refData = toRefs(data);
    return {
      ...refData,
      getData,
      goLink
    }

  }
});
